import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Date } from 'prismic-reactjs'
import styled from "styled-components"

const StyledDiv2 = styled.div`
  background-image: linear-gradient(to bottom, #ffffff, #f0f5f5);
`

const StyledImg = styled.img`
  object-fit: cover;
  object-position: center top;
`

const h2Style = "text-xl md:text-2xl mb-4 font-extrabold md:line-clamp-3 mb-6"
const dateStyle = "text-sm mb-6"
const viaStyle = "text-sm mb-10"

const SectionFeaturedNewsLinks = () => {
  const data = useStaticQuery(graphql`
    query SectionFeaturedNewsLinksQuery {
      prismicNewsroomPage {
        data {
          first_featured_news_title
          first_featured_news_date_published
          first_featured_news_link_url {
              url
            }
          first_featured_news_link_image_url {
              url
            }
          first_featured_news_link_source_name
          second_featured_news_title
          second_featured_news_date_published
          second_featured_news_link_url {
              url
            }
          second_featured_news_link_image_url {
              url
            }
          second_featured_news_link_source_name
          third_featured_news_title
          third_featured_news_date_published
          third_featured_news_link_url {
              url
            }
          third_featured_news_link_image_url {
              url
            }
          third_featured_news_link_source_name          
        }
      }
      imgNewsLinkImagePlaceholder: file(relativePath: { eq: "backgrounds/news-link-image-placeholder-bg-new.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1200
          )
        }
      }
    }
  `)

  const doc = data.prismicNewsroomPage
  const imgNewsLinkImagePlaceholder = getImage(data.imgNewsLinkImagePlaceholder)
  const datePostedFirst = Date(doc.data.first_featured_news_date_published)
  const formattedDateFirst = new Intl.DateTimeFormat('en-US',{
    year: "numeric",
    month: "short",
    day: "numeric"
  }).format(datePostedFirst);
  const datePostedSecond = Date(doc.data.second_featured_news_date_published)
  const formattedDateSecond = new Intl.DateTimeFormat('en-US',{
    year: "numeric",
    month: "short",
    day: "numeric"
  }).format(datePostedSecond);
  const datePostedThird = Date(doc.data.third_featured_news_date_published)
  const formattedDateThird = new Intl.DateTimeFormat('en-US',{
    year: "numeric",
    month: "short",
    day: "numeric"
  }).format(datePostedThird);

  return (
    <StyledDiv2 className="pt-10 pb-20">

      <div className="w-11/12 md:w-8/12 xl:w-5/12 mx-auto border-b-2 border-site-gray-dark pb-5">

        <h2 className="text-site-red text-2xl md:text-4xl uppercase font-extrabold text-center tracking-wider">
          Featured News
        </h2>

      </div>

      <div className="relative w-11/12 lg:w-10/12 max-w-screen-xl grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 mx-auto mt-16">

        <div className="relative bg-white border border-gray-400 shadow-lg">
          <div>
            <a href={doc.data.first_featured_news_link_url.url} target="_blank" rel="noopener noreferrer">
              {doc.data.first_featured_news_link_image_url
                ?
                <StyledImg
                  src={doc.data.first_featured_news_link_image_url.url}
                  alt={doc.data.first_featured_news_title}
                  className="w-full h-48"
                />
                :
                <GatsbyImage
                  image={imgNewsLinkImagePlaceholder}
                  alt={doc.data.first_featured_news_title}
                  className="w-full h-48"
                />
              }
            </a>
          </div>

          <div className="p-6">
            <p className={dateStyle}>{formattedDateFirst}</p>
            <a href={doc.data.first_featured_news_link_url.url} target="_blank" rel="noopener noreferrer">
              {doc.data.first_featured_news_title.length >= 75
                ?
                <div className="tooltip relative">
                  <h2 className={h2Style}>
                    {doc.data.first_featured_news_title}
                  </h2>
                  <span className="tooltiptext">{doc.data.first_featured_news_title}</span>
                </div>
                :
                <h2 className={h2Style}>
                  {doc.data.first_featured_news_title}
                </h2>
              }
            </a>
            <p className={viaStyle}>via <span className="text-lg font-medium">{doc.data.first_featured_news_link_source_name}</span></p>
            <a href={doc.data.first_featured_news_link_url.url} target="_blank" rel="noopener noreferrer">
              <button className="absolute bottom-0 left-0 right-0 w-full bg-site-red hover:bg-site-red-hover text-sm text-white tracking-wider mt-6 p-3">
                See More
              </button>
            </a>
          </div>
        </div>

        <div className="relative bg-white border border-gray-400 shadow-lg">
          <div>
            <a href={doc.data.second_featured_news_link_url.url} target="_blank" rel="noopener noreferrer">
              {doc.data.second_featured_news_link_image_url
                ?
                <StyledImg
                  src={doc.data.second_featured_news_link_image_url.url}
                  alt={doc.data.second_featured_news_title}
                  className="w-full h-48"
                />
                :
                <GatsbyImage
                  image={imgNewsLinkImagePlaceholder}
                  alt={doc.data.second_featured_news_title}
                  className="w-full h-48"
                />
              }
            </a>
          </div>

          <div className="p-6">
            <p className={dateStyle}>{formattedDateSecond}</p>
            <a href={doc.data.second_featured_news_link_url.url} target="_blank" rel="noopener noreferrer">
              {doc.data.second_featured_news_title.length >= 75
                ?
                <div className="tooltip relative">
                  <h2 className={h2Style}>
                    {doc.data.second_featured_news_title}
                  </h2>
                  <span className="tooltiptext">{doc.data.second_featured_news_title}</span>
                </div>
                :
                <h2 className={h2Style}>
                  {doc.data.second_featured_news_title}
                </h2>
              }
            </a>
            <p className={viaStyle}>via <span className="text-lg font-medium">{doc.data.second_featured_news_link_source_name}</span></p>
            <a href={doc.data.second_featured_news_link_url.url} target="_blank" rel="noopener noreferrer">
              <button className="absolute bottom-0 left-0 right-0 w-full bg-site-red hover:bg-site-red-hover text-sm text-white tracking-wider mt-6 p-3">
                See More
              </button>
            </a>
          </div>
        </div>

        <div className="relative bg-white border border-gray-400 shadow-lg">
          <div>
            <a href={doc.data.third_featured_news_link_url.url} target="_blank" rel="noopener noreferrer">
              {doc.data.third_featured_news_link_image_url
                ?
                <StyledImg
                  src={doc.data.third_featured_news_link_image_url.url}
                  alt={doc.data.third_featured_news_title}
                  className="w-full h-48"
                />
                :
                <GatsbyImage
                  image={imgNewsLinkImagePlaceholder}
                  alt={doc.data.third_featured_news_title}
                  className="w-full h-48"
                />
              }
            </a>
          </div>

          <div className="p-6">
            <p className={dateStyle}>{formattedDateThird}</p>
            <a href={doc.data.third_featured_news_link_url.url} target="_blank" rel="noopener noreferrer">
              {doc.data.third_featured_news_title.length >= 75
                ?
                <div className="tooltip relative">
                  <h2 className={h2Style}>
                    {doc.data.third_featured_news_title}
                  </h2>
                  <span className="tooltiptext">{doc.data.third_featured_news_title}</span>
                </div>
                :
                <h2 className={h2Style}>
                  {doc.data.third_featured_news_title}
                </h2>
              }
            </a>
            <p className={viaStyle}>via <span className="text-lg font-medium">{doc.data.third_featured_news_link_source_name}</span></p>
            <a href={doc.data.third_featured_news_link_url.url} target="_blank" rel="noopener noreferrer">
              <button className="absolute bottom-0 left-0 right-0 w-full bg-site-red hover:bg-site-red-hover text-sm text-white tracking-wider mt-6 p-3">
                See More
              </button>
            </a>
          </div>
        </div>
      </div>
    </StyledDiv2>
  )
}

export default SectionFeaturedNewsLinks
