import * as React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import { RichText } from "prismic-reactjs"
import { Date } from 'prismic-reactjs'
import styled from "styled-components"

import LayoutNew from "../components/layout-new"
import HeroSection from "../components/common/hero-section"
import Seo from "../components/seo"
import htmlSerializer from "../utils/htmlSerializer"
import SectionFeaturedNewsLinks from "../components/sections/Section-featured-news-links"

const StyledImg = styled.img`
  object-fit: cover;
  object-position: center top;
`

const h2Style = "text-xl md:text-2xl mb-4 font-extrabold md:line-clamp-3 mb-6"
const dateStyle = "text-sm mb-6"
const viaStyle = "text-sm mb-10"

const PageNewsroom = () => {
  const data = useStaticQuery(graphql`
    query PageNewsroomQuery {
      prismicNewsroomPage {
        data {
          page_title
          page_short_description
          red_bubble_title
          red_bubble_text {
            richText
          }
          page_hero_background {
            gatsbyImageData(
              width: 1920
            )
          }
          news_links {
            news_title
            date_published
            news_link_url {
              url
            }
            news_link_image_url {
              url
            }
            news_link_source_name
          }
        }
      }
      imgNewsLinkImagePlaceholder: file(relativePath: { eq: "backgrounds/news-link-image-placeholder-bg-new.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1200
          )
        }
      }
    }
  `)

  const doc = data.prismicNewsroomPage
  // Sort newsLinks by date_published in descending order
  const sortedNewsLinks = doc.data.news_links.sort((a, b) => new Date(b.date_published) - new Date(a.date_published))
  const imgHero = getImage(doc.data.page_hero_background)
  const imgSrc = getSrc(doc.data.page_hero_background)
  const imgNewsLinkImagePlaceholder = getImage(data.imgNewsLinkImagePlaceholder)

  return (
    <LayoutNew>
      <Seo
        title={doc.data.page_title}
        description={doc.data.page_short_description}
        image={imgSrc}
      />

      <HeroSection
        title={doc.data.page_title}
        imgSrc={imgHero}
      />

      <div>
        <div className="w-11/12 lg:w-10/12 xl:w-11/12 max-w-screen-lg bg-site-red mx-auto py-6 px-6 md:px-16 -mt-8 md:-mt-16 z-50 rounded-3xl shadow-lg">
          <h2 className="text-white font-bold text-center text-xl md:text-2xl">{doc.data.red_bubble_title}</h2>
          <div className="text-white font-medium text-center text-lg md:text-xl mt-6 mb-0">
            <RichText
              render={doc.data.red_bubble_text.richText}
              htmlSerializer={htmlSerializer}
            />
          </div>
        </div>
      </div>

      <SectionFeaturedNewsLinks />

      <div className="bg-site-green-light py-12 lg:py-24">
        <div className="w-11/12 md:w-8/12 xl:w-5/12 mx-auto border-b-2 border-site-gray-dark pb-5">
          <h2 className="text-site-red text-2xl md:text-4xl uppercase font-extrabold text-center tracking-wider">View All</h2>
        </div>

        <div className="relative w-11/12 lg:w-10/12 max-w-screen-xl grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 mx-auto mt-16">

          {sortedNewsLinks.map(item => {
            const datePosted = Date(item.date_published)
            const formattedDate = new Intl.DateTimeFormat('en-US',{
              year: "numeric",
              month: "short",
              day: "numeric"
            }).format(datePosted);

            return (
              <div className="relative bg-white border border-gray-100 shadow-lg">
                <div>
                  <a href={item.news_link_url.url} target="_blank" rel="noopener noreferrer">
                    {item.news_link_image_url.url
                      ?
                      <StyledImg
                        src={item.news_link_image_url.url}
                        alt={item.news_title}
                        className="w-full h-48"
                      />
                      :
                      <GatsbyImage
                        image={imgNewsLinkImagePlaceholder}
                        alt={item.news_title}
                        className="w-full h-48"
                      />
                    }
                  </a>
                </div>

                <div className="p-6">
                  <p className={dateStyle}>{formattedDate}</p>
                  <a href={item.news_link_url.url} target="_blank" rel="noopener noreferrer">
                    {item.news_title.length >= 75
                      ?
                      <div className="tooltip relative">
                        <h2 className={h2Style}>
                          {item.news_title}
                        </h2>
                        <span className="tooltiptext">{item.news_title}</span>
                      </div>
                      :
                      <h2 className={h2Style}>
                        {item.news_title}
                      </h2>
                    }
                  </a>
                  <p className={viaStyle}>via <span className="text-lg font-medium">{item.news_link_source_name}</span></p>
                  <a href={item.news_link_url.url} target="_blank" rel="noopener noreferrer">
                    <button className="absolute bottom-0 left-0 right-0 w-full bg-site-red hover:bg-site-red-hover text-sm text-white tracking-wider mt-6 p-3">
                      See More
                    </button>
                  </a>
                </div>
              </div>
            )
          })}

        </div>

      </div>
    </LayoutNew>
  )
}

export default PageNewsroom
